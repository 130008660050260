<template>
    <div class="password-field form-group">
        <label :for="inputId">
            {{ label }}
        </label>
        <div class="position-relative">
            <input
                ref="input"
                :id="inputId"
                :type="isPasswordRevealed ? 'text': 'password'"
                :name="inputName"
                :class="{'is-invalid': isInvalid}"
                class="password-field__input form-control"
                v-model="password"
                required
            >
            <button
                v-if="!isInvalid"
                class="password-field__show-btn position-absolute"
                @click.prevent="showButtonClickHandler"
                title="Afficher/Masquer le mot de passe"
            >
            </button>
        </div>

        <div
            v-if="checkEntropy"
            :class="`password-field__indicator--${entropyLevel}`"
            class="password-field__indicator mt-2 d-flex justify-content-end align-items-center"
        >
            <span class="caption ml-2">{{ labels[entropyLevel] }}</span>
        </div>

        <div v-if="errors && errors.length" class="caption text-danger">
            <template v-for="(error, key) in errors">
                <span :key="key">{{ error }}<br></span>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "PasswordField",

    props: {
        inputId: {
            type: String,
            required: true,
        },
        inputName: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required :true,
        },
        errors: {
            type: Array,
            default: null,
        },
        checkEntropy: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            entropy: require('fast-password-entropy'),

            password: null,

            labels: [
                'Sécurité insuffisante',
                'Sécurité faible',
                'Sécurité moyenne',
                'Sécurité forte',
            ],

            isPasswordRevealed: false
        }
    },

    computed: {
        entropyLevel() {
            if (!this.checkEntropy || !this.password || this.password.length < 12) {
                if (this.password && this.password.length < 12) {
                    this.$refs.input.setCustomValidity('Le mot de passe doit comporter au minimum 12 caractères');
                }

                return 0;
            }

            let entropyValue = this.entropy(this.password);

            if (entropyValue >= 200) {
                this.$refs.input.setCustomValidity('');
                return 3;
            }

            if (entropyValue >= 100) {
                this.$refs.input.setCustomValidity('');
                return 2;
            }

            if (entropyValue >= 80) {
                this.$refs.input.setCustomValidity('');
                return 1;
            }

            this.$refs.input.setCustomValidity('Le niveau de sécurité de votre mot de passe étant insuffisant, ce dernier n\'a pas été validé. N\'hésitez pas à choisir un mot de passe comportant davantage de caractères.');
            return 0;
        },

        isInvalid() {
            return this.errors && this.errors.length && !this.password;
        },
    },

    methods: {
        showButtonClickHandler() {
            this.isPasswordRevealed = !this.isPasswordRevealed;
            this.$refs.input.focus();
        },
    }
}
</script>

<style scoped lang="scss">
    .password-field {
        &__input {
            padding-right: 3rem;
        }

        &__show-btn {
            top: 50%;
            right: 0.75rem;
            transform: translateY(-50%);

            width: 1.5rem;
            height: 1.5rem;

            background-image: url('~@/static/images/icons/eye.svg');
            background-size: cover;
        }

        &__indicator {
            transition: width 150ms ease;

            &--0 {
                width: 50%;

                @media (min-width: 768px) {
                    width: 25%;
                }

                &::before {
                    background-color: #000;
                }
            }

            &--1 {
                width: 50%;

                &::before {
                    background-color: #F45252;
                }
            }

            &--2 {
                width: 75%;

                &::before {
                    background-color: #F4BE52;
                }
            }

            &--3 {
                width: 100%;

                &::before {
                    background-color: #25A76A;
                }
            }

            &::before {
                content: '';
                flex: 1;
                height: 0.50rem;
                border-radius: 0.25rem;
            }
        }
    }
</style>
